<template>
  <main class="content-page">
    <section class="submenu">
      <span class="highlight"></span>
      <h1 class="-h6">Squads</h1>

      <section class="actions">
        <div class="btn-container">
          <Button :state="'primary'" :title="'Create New Squad'" />
        </div>
        <div class="btn-container">
          <Button :state="'secondary'" :title="'Invite Member to Squad'" />
        </div>
        <div class="search-container">
          <SearchBar />
        </div>
      </section>
    </section>
    <section
      class="comingsoon"
      style="
        display: flex;
        justify-content: center;
        align-items: center;
        height: 70%;
      "
    >
      <p class="-h1">Coming Soon...</p>
    </section>
  </main>
</template>

<script>
import SearchBar from "../../../components/utils/BaseSearchBar.vue";
import Button from "../../../components/utils/BaseButton.vue";
export default {
  components: { SearchBar, Button },
  methods: {},
};
</script>

<style scoepd>
@import url("../../../assets/scopeListings.css");
.btn-container {
  width: auto;
}
</style>